<script setup lang="ts">
import type { ComponentBannerOurBrandsProps } from "./ComponentBannerOurBrands.props"
const props = defineProps<ComponentBannerOurBrandsProps>()
</script>

<template>
  <div class="cms-component-banner-our-brands">
    <BannerBrandExclusiveBrands v-if="props" v-bind="props" />
  </div>
</template>
