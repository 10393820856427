<script setup lang="ts">
import { ExclusiveBrandsProps } from "./ExclusiveBrands.props"
import { smOptions, mdOptions } from "./ExclusiveBrands.carouselOptions"
import { sm, md, lg } from "~/utils/breakpoints"

const props = defineProps<ExclusiveBrandsProps>()

const carouselOptions = computed(() => {
  if (sm.value) return smOptions
  return mdOptions
})

const bgImage = computed(() => {
  if (!!props.bgImageSmall && sm.value) return props.bgImageSmall
  else if (!!props.bgImageMedium && md.value && !lg.value)
    return props.bgImageMedium

  return props.bgImageLarge
})
</script>

<template>
  <div
    class="
      relative
      items-center
      overflow-hidden
      px-4
      py-6
      md:grid md:grid-cols-12
      md:gap-6
      md:pt-11
    "
  >
    <UtilsWrapperImage
      v-if="bgImage"
      v-bind="bgImage"
      :key="bgImage.image.url"
      class="wrapper-image-full-container-centered absolute inset-0 -z-10"
    />
    <div class="md:col-span-4 md:col-start-2">
      <p v-if="title" class="elephant-bold mb-2 md:mb-4">{{ title }}</p>

      <UtilsMarkdown
        v-if="text"
        class="
          pig-regular
          markdown__link-underlined
          markdown__link-pig-medium
          markdown__link-hover-green-main
          mb-7
          text-black-80
        "
        :content="text"
      />

      <!-- md & lg button -->
      <UtilsButton
        v-if="cta"
        class="hidden w-[267px] md:block lg:w-[297px]"
        v-bind="cta"
        theme="outlined-green"
      />
    </div>

    <div
      v-if="brands.length <= 3 && md"
      class="md:col-span-2"
      v-for="(brand, key) in brands"
      :key="key"
    >
      <BannerBrandCard v-bind="brand" />
    </div>
    <div
      v-else
      class="
        carousel-container-overflowed
        -mx-4
        px-4
        md:col-span-6
        md:mx-0
        md:overflow-x-hidden
        md:px-0
      "
    >
      <AppCarousel
        class="carousel-overflowed select-none"
        :swiper-props="carouselOptions"
        :items="brands"
        :theme="carouselOptions.theme"
        :key="sm ? 1 : 0"
      >
        <template #default="{ item }">
          <BannerBrandCard v-bind="item" />
        </template>
      </AppCarousel>
    </div>

    <div v-if="cta" class="inline-flex w-full justify-center md:hidden">
      <UtilsButton class="mt-4 w-full" v-bind="cta" theme="outlined-green" />
    </div>
  </div>
</template>
