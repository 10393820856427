import { Pagination, Navigation } from "swiper/modules"
import { CarouselProps } from "~/utils/carouselProps"

export const smOptions: CarouselProps = {
  slidesPerView: "auto",
  spaceBetween: 16,
  pagination: {
    enabled: true
  },
  modules: [Pagination],
  theme: "pagination-green-white-small-padding"
}

export const mdOptions: CarouselProps = {
  slidesPerView: 3,
  slidesPerGroup: 3,
  spaceBetween: 24,
  navigation: true,
  grabCursor: true,
  pagination: {
    type: "progressbar"
  },
  modules: [Navigation, Pagination],
  theme: "navigation-dark-bottom"
}
